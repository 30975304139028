export enum BidStatus {
  Candidate = "CANDIDATE",
  Rejected = "REJECTED",
  Approved = "APPROVED",
}

export enum BidType {
  Private = "PRIVATE",
  Company = "COMPANY",
}

export enum SuspensiveConditionWeek {
  Two = "2",
  Three = "3",
  Four = "4",
}
