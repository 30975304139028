
  import { BidRowColumn } from "@/components/bid/table/row.vue";
  import { Component, Watch, Vue } from "vue-property-decorator";
  import { Getter, Action } from "vuex-class";
  import { FormTextarea, FormBase, FormError, FormSubmit, FormInput } from "@/components/forms";
  import { formatCurrency, formatDate } from "@/utils/formatters";
  import { BidStatus } from "@/enums/bid";
  import { DocumentFolder } from "@/store/modules/document.store";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      BidHeader: () => import("@/components/bid/table/header.vue"),
      BidRow: () => import("@/components/bid/table/row.vue"),

      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormTextarea,
      FormInput,
      FormError,
      FormSubmit,
    },
  })
  export default class PartDossierTabBids extends Vue {
    @Getter("activity/viewing") activity!: Activity;

    @Getter("bid/all") bids!: Bid[];

    @Action("bid/index") indexBids!: BidIndex;

    loaded: boolean = false;

    DocumentFolder = DocumentFolder;

    columns: BidRowColumn[] = [
      { type: "date", width: "110px", label: "Datum", headerCenter: false, center: false },
      { type: "amount", width: "110px", label: "Bedrag", headerCenter: false, center: false },
      { type: "expiration_date", width: "110px", label: "Vervaldatum", headerCenter: false, center: false },
      { type: "candidates", width: "160px", label: "Kandidaat", headerCenter: false, center: false },
      { type: "suspensive_conditions", width: "200px", label: "Opschortende voorwaarden", headerCenter: false, center: false },
      { type: "extra", width: "100px", label: "Meer info", headerCenter: false, center: false },
      { type: "CTA", width: "auto", label: "", headerCenter: false, end: true },
    ];

    async mounted() {
      await this.indexBids({ activity_id: this.activity.id });

      this.loaded = true;

      // TODO catch: something went wrong fetching the bids
    }

    get pendingBids() {
      return this.bids.filter((bid) => bid.status !== BidStatus.Rejected);
    }

    get rejectedBids() {
      return this.bids.filter((bid) => bid.status === BidStatus.Rejected);
    }

    get missingRequiredFieldsForViewingBid() {
      let fields = [];
      if (!this.viewingBid?.files.some((f) => f.folder === DocumentFolder.approvedSalesAgreement)) {
        fields.push("file_approved_sales_agreement");
      }

      if (!this.viewingBid.signing_details?.counter_signed_at) {
        fields.push("counter_signed_at");
      }

      if (
        (this.viewingBid.signing_details?.unknown_notary === null || this.viewingBid.signing_details?.unknown_notary === undefined) ||
        (this.viewingBid.signing_details?.unknown_notary === false && (this.viewingBid.signing_details?.notary_info === null || this.viewingBid.signing_details?.notary_info === ''))
        ) {
        fields.push("notary_info");
      }

      if (
        this.viewingBid.type === "COMPANY" &&
        (!this.viewingBid.company_details ||
          !this.viewingBid.company_details?.name ||
          !this.viewingBid.company_details?.vat_number ||
          !this.viewingBid.company_details?.corporate_headquarters)
      ) {
        fields.push("company_details");
      }

      if (
        !this.viewingBid.representatives.every(
          (r) =>
            r.first_name &&
            r.last_name &&
            r.email &&
            r.phone &&
            r.street &&
            r.number &&
            r.city &&
            r.zip &&
            r.country &&
            r.national_register_number &&
            r.birth_date &&
            r.birth_place &&
            r.birth_country,
        )
      ) {
        fields.push("representatives");
      }

      return fields;
    }

    /**
     * ------------------
     * OLD code
     * ------------------
     */
    @Getter("bid/viewing") viewingBid!: Bid;
    @Getter("bid/winningBid") winningBid!: Bid | undefined;

    @Action("bid/patch") updateBid!: BidUpdate;
    @Action("bid/read") readBid!: BidRead;

    action: string | null = null;

    reject_reason: string | null = null;

    formatCurrency = formatCurrency;

    formatDate = formatDate;

    handleApproveBid() {
      const payload = {
        id: this.viewingBid.id,
        activity_id: this.activity.id,
        status: "APPROVED",
      };
      this.updateBid(payload).then(() => {
        this.$toast.open({ message: this.$t("views.bids.approve_success") as string, type: "success", position: "bottom-right" });
        this.action = null;
      });
    }

    handleUnvealBid() {
      const payload = {
        id: this.viewingBid.id,
        activity_id: this.activity.id,
        visible: true,
      };
      this.updateBid(payload).then(() => {
        this.$toast.open({ message: this.$t("views.bids.unveal_success") as string, type: "success", position: "bottom-right" });
        this.action = null;
      });
    }

    handleUndoRejectBid() {
      const payload = {
        activity_id: this.activity.id,
        id: this.viewingBid.id,
        status: "CANDIDATE",
        rejection_reason: null,
      };
      this.updateBid(payload).then(() => {
        this.$toast.open({ message: this.$t("views.bids.reject_undo_success") as string, type: "success", position: "bottom-right" });
        this.action = null;
      });
    }

    handleRejectBid() {
      const payload = {
        id: this.viewingBid.id,
        activity_id: this.activity.id,
        status: "REJECTED",
        rejection_reason: this.reject_reason,
      };
      this.updateBid(payload).then(() => {
        this.$toast.open({ message: this.$t("views.bids.reject_success") as string, type: "success", position: "bottom-right" });
        this.action = null;
        this.reject_reason = null;
      });
    }

    handleReopenBids() {
      if (this.winningBid) {
        const payload = {
          id: this.winningBid.id,
          activity_id: this.activity.id,
          status: "CANDIDATE",
        };
        this.updateBid(payload).then(() => {
          this.$toast.open({ message: this.$t("views.bids.reopen_success") as string, type: "success", position: "bottom-right" });
          this.action = null;
        });
      }
    }

    resetAction() {
      this.action = null;
    }

    approveBid(id: number) {
      this.readBid({ id: id });

      this.action = "approve";
    }

    rejectBid(id: number) {
      this.readBid({ id: id });
      this.action = "reject";
    }

    unveilBid(id: number) {
      this.readBid({ id: id });
      this.action = "unveal";
    }

    showBidDropdown(id: number) {
      this.readBid({ id: id });
      this.action = "show-dropdown";
    }

    undoRejectBid(id: number) {
      this.readBid({ id: id });
      this.action = "undo-reject";
    }
  }
